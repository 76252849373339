
  import React from 'react';
  import COMPONENTS from '../../../components';
  import { get } from 'lodash';
  import PageLayout from '../../../layout';
  import { graphql } from 'gatsby';

  class Page extends React.Component {
    state = {
      data: [],
    };

    componentDidMount() {
      let slices;

      const data = get(this, 'props.data.careersNbtDesignJson');

      if (data) {
        slices = Object.entries(data).map(([key, content]) => ({
          componentName: key,
          ...content,
        }));

        slices.sort(
          ({ position: positionA }, { position: positionB }) =>
            positionA - positionB
        );

        this.setState({
          data: slices,
        });
      }
    }

    render() {
      return (
        <PageLayout>
          {get(this, 'state.data').map(({ componentName, data }, index) => {
            const ComponentType = COMPONENTS[componentName];
            return <ComponentType key={index} {...data} />;
          })}
        </PageLayout>
      );
    }
  }

  export default Page;

  export const pageQuery = graphql`
    query {
      careersNbtDesignJson {
        
      
      
      
      
      CTAWith2Buttons0 {
        position
        componentName
        data {
           background title description buttonDark {  text link } buttonLight {  text link }
        }
      }
    
      BannerMask1 {
        position
        componentName
        data {
           media {  type src } corners height
        }
      }
    
      ProductSingle2 {
        position
        componentName
        data {
           title textBody image
        }
      }
    
      OpenPositions3 {
        position
        componentName
        data {
           alwaysShowAdditionalLink callToAction {  title textBody button {  text link } } companyId department htmlElementId maxRows additionalLink {  text link }
        }
      }
    
      }
    }
  `